import basic from '@/common/basic.js'
import json from '@/common/json.js'
import Cookie from 'vue-cookies';
import { defineStore } from 'pinia'

export const useSettingStore = defineStore('setting', {
    // 为了完整类型推理，推荐使用箭头函数
    state: () => {
        return {
            // 所有这些属性都将自动推断出它们的类型
            isLogin: basic.chStr2Int(Cookie.get('isLogin')),
            isBind: 1,//basic.chStr2Int(Cookie.get('isBind')),
            memLev: basic.chStr2Int(Cookie.get('memlev')),
            langCur: basic.chStr2Int(Cookie.get('lang'), 1),
            langDic: json.sync('/include/dic2.json'),
            config: json.sync('/config.json'),
            pngPre: 'data:image/png;base64,',
            wh: document.body.clientHeight,// window.innerHeight,// document.body.clientHeight;
            ww: document.body.clientWidth,//window.innerWidth,// document.body.clientWidth;
            isFull: false,
            logoUrl: '',
            face: '',
            userName: '',
            showName: '',
            topNavAlias: '',
            teaLev: 2,
            wxState: basic.chStr2Int(Cookie.get('wxState'), 0),
        }
    },
    getters: {
        isTeacher(state) {
            return state.memLev == state.teaLev;
        }
    },
    actions: {
        SET_ISLOGIN(v) {
            if (v != this.isLogin) {
                this.isLogin = v;
                Cookie.set('isLogin', v, -1);
                if (v == 0) {
                    this.SET_ISBIND(0);
                }
            }
        },
        SET_ISBIND(v) {
            if (v != this.isBind) {

                this.isBind = 1;// v;
                Cookie.set('isBind', 1, -1);
                //Cookie.set('isBind', v, -1);
            }
        },
        SET_MEMLEV(v) {
            if (this.memLev != v) {
                this.memLev = v;
                Cookie.set('memlev', v, -1);
            }
        },
        SET_LANGCUR(v) {
            let _lang = 0;
            if (v) {
                _lang = v;
            }
            else {
                let clang = basic.chStr2Int(Cookie.get('lang'));
                if (clang == 0) {
                    _lang = (navigator.language || navigator.browserLanguage || navigator.userLanguage).toLowerCase() == "zh-cn" ? 1 : 2;
                }
            }
            if ((_lang > 0) && (this.langCur != _lang)) {
                this.langCur = _lang;
                Cookie.set('lang', _lang, -1);
            }

        },
        SET_LOGOURL(v) {
            if (this.logoUrl != v) {
                this.logoUrl = v;
            }
        },
        SET_USERNAME(v) {
            if (this.userName != v) {
                this.userName = v;
            }
        },
        SET_SHOWNAME(v) {
            if (this.showName != v) {
                this.showName = v;
            }
        },
        SET_FACE(v) {
            if (this.face != v) {
                this.face = v;
            }
        },

        SET_WINDOWSIZE(state) {
            this.wh = window.innerHeight;// document.body.clientHeight;
            this.ww = window.innerWidth;// document.body.clientWidth;
        },
        SET_TOPNAVALIAS(v) {
            if (this.topNavAlias != v) {
                this.topNavAlias = v;
            }
        },
        SET_WXSTATE() {
            let v = Math.floor(Math.random() * 1000000) + 1;
            Cookie.set('wxState', v, -1);
            this.wxState = v;
        },
        async setIsLogin(v) {
            this.SET_ISLOGIN(v);
        },
        async setIsBind(v) {
            this.SET_ISBIND(v);
        },
        async setMemLev(v) {
            this.SET_MEMLEV(v);
        },
        async setLangCur(v) {
            this.SET_LANGCUR(v);
        },
        async setlangcur(v) {
            this.SET_LANGCUR(v);
        },
        async setlogoUrl(v) {
            this.SET_LOGOURL(v);
        },
        async setUserName(v) {
            this.SET_USERNAME(v);
        },
        async setShowName(v) {
            this.SET_SHOWNAME(v);
        },
        async setFace(v) {
            this.SET_FACE(v);
        },
        async setTopNavAlias(v) {
            this.SET_TOPNAVALIAS(v);
        }

    }
});
