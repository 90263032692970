import { unref as _unref, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  __name: 'index',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const pagemain = computed(() => {
      let folder = route.params.root || 'home';
      folder = folder.toLowerCase();
      let filename = route.params.sub || folder;
      return defineAsyncComponent(() => import('@/views/' + folder + '/' + filename + ".vue"));
    });
    onMounted(() => {
      let folder = route.params.root;
      if (folder) {
        folder = folder.toLowerCase();
      }
      if (!proxy.$store.isLogin && folder != 'login') {
        router.replace('/login');
      }
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_resolveDynamicComponent(_unref(pagemain)), _toHandlers(_ctx.$attrs), null, 16);
    };
  }
};