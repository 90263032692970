import { createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import Cheader from '@/components/Cheader.vue';
export default {
  __name: 'App',
  setup(__props) {
    onMounted(() => {
      let aprevent = (e, href) => {
        let hreflast = href ? href.substring(href.length - 1) : '';
        if (hreflast == "#") {
          if (e.preventDefault) {
            e.preventDefault();
          } else {
            e.returnValue = false;
          }
          //return false;
        }
      };

      window.addEventListener('click', e => {
        if (e.target.tagName) {
          if (e.target.tagName === 'A') {
            aprevent(e, e.target.href);
          } else {
            if (e.target.parentNode) {
              if (e.target.parentNode.tagName === 'A') {
                aprevent(e, e.target.parentNode.href);
              } else {
                if (e.target.parentNode.parentNode) {
                  if (e.target.parentNode.parentNode.tagName === 'A') {
                    aprevent(e, e.target.parentNode.parentNode.href);
                  }
                }
              }
            }
          }
        }
      });
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", {
        id: "app",
        class: _normalizeClass([_ctx.$store.isFull ? 'appfull' : 'appnormal'])
      }, [_createVNode(Cheader), _createVNode(_component_router_view)], 2);
    };
  }
};