import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import VChart from 'vue-echarts';
import * as echarts from 'echarts/core';
import { BarChart, LineChart, PieChart, MapChart, RadarChart, ScatterChart, EffectScatterChart, LinesChart } from 'echarts/charts';
import { GridComponent, PolarComponent, GeoComponent, TooltipComponent, LegendComponent, TitleComponent, VisualMapComponent, MarkPointComponent, MarkLineComponent, DatasetComponent, ParallelComponent, ToolboxComponent, TimelineComponent, DataZoomComponent } from 'echarts/components';
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers';
//const { use, registerMap, registerTheme } = echarts;
import chinaMap from './china.json';
//import worldMap from '/include/echarts/world.json';
// registering map data

export default {
  __name: 'ffcharts',
  props: {
    chartsInfo: {
      type: Object,
      default: () => {}
    }
  },
  setup(__props) {
    const FFprops = __props;
    const {
      use,
      registerMap
    } = echarts;
    use([BarChart, LineChart, PieChart, MapChart, RadarChart, ScatterChart, EffectScatterChart, LinesChart, GridComponent, PolarComponent, GeoComponent, TooltipComponent, LegendComponent, TitleComponent, VisualMapComponent, DatasetComponent, MarkPointComponent, MarkLineComponent, CanvasRenderer, SVGRenderer, ParallelComponent, ToolboxComponent, TimelineComponent, DataZoomComponent]);

    // custom theme
    //import theme from './theme.json';
    // Map of China
    registerMap('china', chinaMap);
    //registerMap('world', worldMap);
    // registering custom theme
    //registerTheme('ovilia-green', theme);

    const {
      proxy
    } = getCurrentInstance();
    const FFdata = reactive({
      isLoad: false,
      dfbar: {
        title: {
          left: 'center',
          text: ''
        },
        grid: {
          top: '130px'
        },
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          name: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: '40px',
          left: 'right'
        }
      },
      dfmark: {
        markPoint: {
          data: [{
            type: 'max',
            name: proxy.$common.langInfo('最大值', 'Max')
          }, {
            type: 'min',
            name: proxy.$common.langInfo('最小值', 'Min')
          }]
        },
        markLine: {
          data: [{
            type: 'average',
            name: proxy.$common.langInfo('平均值', 'Average')
          }],
          label: {
            position: 'end',
            formatter: proxy.$common.langInfo('平均值', 'Average')
          }
        }
      },
      dfpie: {
        title: {
          left: 'center',
          text: ''
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '40px',
          orient: 'vertical',
          right: 'right'
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      },
      dfseriespie: {
        name: '',
        type: 'pie',
        radius: '70%',
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    });
    const CchartsInfo = computed(() => {
      let _chartsinfo = proxy.$common.basic.jsonClone(FFprops.chartsInfo);
      switch (_chartsinfo.chartstype) {
        case 1:
          //bar
          _chartsinfo = proxy.$common.basic.FFextend(true, {}, FFdata.dfbar, _chartsinfo);
          break;
        case 2:
          //line
          _chartsinfo = proxy.$common.basic.FFextend(true, {}, FFdata.dfbar, _chartsinfo);
          break;
        case 3:
          //pie
          _chartsinfo = proxy.$common.basic.FFextend(true, {}, FFdata.dfpie, _chartsinfo);
          break;
      }
      if (_chartsinfo.title.text) {
        _chartsinfo.title.text = proxy.$common.langTitle(_chartsinfo.title.text);
      }
      if (_chartsinfo.legend) {
        proxy.$common.each(_chartsinfo.legend.data, (item, index) => {
          _chartsinfo.legend.data[index] = proxy.$common.langTitle(item);
        });
      }
      if (_chartsinfo.xAxis) {
        _chartsinfo.xAxis.name = proxy.$common.langTitle(_chartsinfo.xAxis.name);
        proxy.$common.each(_chartsinfo.xAxis.data, (item, index) => {
          _chartsinfo.xAxis.data[index] = proxy.$common.langTitle(item);
        });
        _chartsinfo.xAxis.axisTick = {
          alignWithLabel: true
        };
      }
      if (_chartsinfo.yAxis) {
        _chartsinfo.yAxis.name = proxy.$common.langTitle(_chartsinfo.yAxis.name);
      }
      proxy.$common.each(_chartsinfo.series, item => {
        item.name = proxy.$common.langTitle(item.name);
        proxy.$common.each(item.data, item2 => {
          if (item2.name) {
            item2.name = proxy.$common.langTitle(item2.name);
          }
        });
        if (item.type == 'line' || item.type == 'bar') {
          if (item.ismark) {
            let _item = proxy.$common.basic.FFextend(true, {}, FFdata.dfmark, item);
            proxy.$common.basic.FFextend(true, item, _item);
          }
        }
        if (item.type == 'pie') {
          let _item = proxy.$common.basic.FFextend(true, {}, FFdata.dfseriespie, item);
          proxy.$common.basic.FFextend(true, item, _item);
          item.label = {
            alignTo: 'edge',
            formatter: '{name|{b}}\n' + item.name + '：{c}',
            minMargin: 5,
            edgeDistance: 10,
            lineHeight: 18,
            rich: {
              time: {
                fontSize: 10,
                color: '#999'
              }
            }
          };
          item.labelLine = {
            length: 15,
            length2: 0,
            maxSurfaceAngle: 80
          };
          item.labelLayout = params => {
            var isLeft = params.labelRect.x < proxy.$store.state.ww / 2;
            var points = params.labelLinePoints;
            // Update the end point.
            points[2][0] = isLeft ? params.labelRect.x : params.labelRect.x + params.labelRect.width;
            return {
              labelLinePoints: points
            };
          };
        }
      });
      if (_chartsinfo.chartstype == 1 || _chartsinfo.chartstype == 2) {
        let maxcount = 8;
        if (_chartsinfo.xcount > maxcount) {
          _chartsinfo.dataZoom = [{
            type: 'slider',
            start: 0,
            end: Math.round(maxcount * 100 / _chartsinfo.xcount)
          }, {
            type: 'inside',
            start: 0,
            end: Math.round(maxcount * 100 / _chartsinfo.xcount)
          }];
        }
      }
      return _chartsinfo;
    });
    onMounted(() => {
      window.setTimeout(() => {
        FFdata.isLoad = true;
      }, 1000);
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(VChart), {
        class: "chartswrap",
        option: _unref(CchartsInfo),
        autoresize: _unref(FFdata).isLoad,
        ref: "mycharts"
      }, null, 8, ["option", "autoresize"]);
    };
  }
};