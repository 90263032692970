import pinia from '@/store/store.js'
import { useSettingStore } from '@/store/setting.js'
import ajax from '@/common/ajax.js'
import basic from '@/common/basic.js'
import eleui from '@/common/eleui.js'
import validate from '@/common/validate.js'
import json from '@/common/json.js'

import 'babel-polyfill'

const store = useSettingStore(pinia);

const FFAPI = "fea";
const FFACT = "fea";
const common = {};

common.basic = basic;//基本方法
common.json = json;//json操作
common.validate = validate.check;//格式验证

////////////////////提示方法
common.alert = (msg, msgtype = 'error', duration = 3) => eleui.alert(common.dicWord(basic.myDecode(msg)), msgtype, duration);
common.success = (msg) => common.alert(msg, 'success');
common.loading = (text) => eleui.loading(common.dicWord(text));
common.loadingClose = (obj) => eleui.loadingClose(obj);
common.confirm = (msg, callbackyes, callbackno, title = '', btnyes = '', btnno = '') => eleui.confirm(common.dicWord(msg), callbackyes, callbackno, title || common.dicWord('confirminfo'), btnyes || common.dicWord('confirm'), btnno || common.dicWord('cancel'));


////////////////////实用方法
common.dicWord = (name, isfield = false) => store.langDic[name] ? (common.langInfo(store.langDic[name][0], store.langDic[name][1] || name, isfield)) : (common.langInfo(name, name, isfield));

common.dicWordCompare = (name, v) => store.langDic[name][0] == v || store.langDic[name][1] == v;

common.langTitle = (v, isfield = false) => {
    let temp = v;
    if (v) {
        let titlecn = v;
        let titleen = "";
        if (v.indexOf('_') > 0) {
            let vs = v.split('_');
            titlecn = vs[0];
            titleen = vs[1];
        }
        temp = common.langInfo(titlecn, titleen, isfield);
    }

    return temp;
}

common.langTitleCompare = (title, v) => {
    if (title != "") {
        if (v.indexOf('_') > 0) {
            let vs = v.split('_');
            return (title == vs[0] && vs[0] != '') || (title == vs[1] && vs[1] != '');
        }
        else {
            return title == v;
        }
    }
    else {
        return false;
    }
}

common.langCN = () => (!store.config.isuseen) || (store.langCur == 1);

common.langInfo = (v1, v2, isfield = false) => {
    v1 = basic.strUnformat(v1);
    v2 = basic.strUnformat(v2);
    return (common.langCN() ? basic.tranEmpty(v1, v2 || '') : basic.tranEmpty(v2, v1 || '')) + (isfield ? common.langCN() ? '：' : ':' : '');
};

common.chGender = (v) => common.dicWord(basic.chGender(v));
common.chStr2GenderInt = (v) => common.dicWordCompare('male', v) ? 1 : common.dicWordCompare('female', v) ? 2 : 0;
common.cgInt2Bool = (v) => common.dicWord(basic.cgInt2Bool(v));
common.chStr2BoolInt = (v) => common.dicWordCompare('yes', v) ? 1 : 0;

common.each = (arr, callback) => {
    if (basic.isArrayExist(arr)) {
        arr.forEach((item, index) => {
            callback(item, index);
        });
    }
};
common.getOp = (opdata, tn) => {
    return basic.FFfind(opdata, "tn", tn);
};

common.getOpData = (opdata, tn) => {
    return common.getOp(opdata, tn).data;
};

common.getOpTitle = (opdata, tn, id) => {
    return common.getOptionTitle(common.getOp(opdata, tn).data, id);
};

common.getOpIdByTitle = (opdata, tn, title) => {
    let opitem = basic.FFfind(common.getOpData(opdata, tn), 'title', title);
    if (!basic.isObjExist(opitem)) {
        opitem = basic.FFfind(common.getOpData(opdata, tn), 'title_en', title);
    }

    return opitem.id || 0;
};

common.getOptionTitle = (op, id, fn = '') => {
    if (basic.isArrayExist(op)) {
        let cop = op.find(item => item.id == id);
        if (cop) {
            if (fn) {
                return cop[fn];
            }
            else {
                if (cop.title_en) {
                    return common.langInfo(cop.title, cop.title_en);
                }
                else {
                    return cop.title;
                }
            }
        }
        else {
            return '';
        }

    }
    else {
        return '';
    }
};

common.getCheckListTitle = (opdata, tn, ids) => {
    let _titles = '';
    common.each(common.getOp(opdata, tn).data, item => {
        if (basic.FFcontain(ids, item.id)) {
            _titles += ',' + common.langInfo(item.title, item.title_en);
        }
    });
    if (_titles != '') { _titles = _titles.substring(1) }
    return _titles;
};

common.getCheckListIds = (opdata, tn, titles) => {
    let ids = '';
    common.each(common.getOp(opdata, tn).data, item => {
        if (basic.FFcontain(titles, item.title)) {
            ids += ',' + item.id;
        }
        else {
            if (basic.FFcontain(titles, item.title_en)) {
                ids += ',' + item.id;
            }
        }
    });
    if (ids != '') { ids = ids.substring(1) }
    return ids;
};

common.chSV = (sv) => {//下拉框的值转换为空
    return sv <= 0 ? "" : sv;
};

common.setTreeData = (listData, levkey = "lev", lev = 0, pidkey = "pid", pid = 0) => {
    let treedata = [];
    if (!basic.chEmpty(listData)) {
        treedata = listData.filter(item => (item[levkey] == lev) && (item[pidkey] == pid));
        common.each(treedata, item => {
            item.x_children = common.setTreeData(listData, levkey, lev + 1, pidkey, item.id);
            item.x_haschildren = common.basic.isArrayExist(item.x_children) ? 1 : -1;

            //Vue.set(item, "x_children", common.setTreeData(listData, levkey, lev + 1, pidkey, item.id));
            //Vue.set(item, "x_haschildren", common.basic.isArrayExist(item.x_children) ? 1 : -1);
        });
    }
    return treedata;
};

common.setCasOptions = (options, nodelist, ppid = 0, ppids = "") => {//增加了ppids，父级的子集是多个,仅限于第一层，叵有需要，以后再改,未经测试

    common.each(nodelist, item => {
        if (ppids ? common.basic.FFcontain(ppids, item.ppid) : item.ppid == ppid || common.basic.FFcontain(item.ppid, ppid)) {
            let newitem = { 'label': common.langInfo(item.title, item.title_en), 'value': item.id, 'pid': item.pid, 'ppid': item.ppid };
            let children = [];
            common.setCasOptions(children, nodelist, newitem.value);
            if (common.basic.isArrayExist(children)) {
                newitem.children = children;
            }
            options.push(newitem);
        }
    });
};


common.getCasFv = (options) => {
    let fv = "";
    common.each(options, item => {
        if (!fv) {
            if (common.basic.isArrayExist(item.children)) {

                fv = common.getCasFv(item.children);
            }
            else {
                fv = item.value;
            }
        }
    });
    return fv;
};

common.chCasSv = (options, v) => {
    let isfind = false;
    common.each(options, item => {
        if (common.basic.isArrayExist(item.children)) {
            if (!isfind) {
                isfind = common.chCasSv(item.children, v);
            }
        }
        else {
            if (v == item.value) {
                isfind = true;
            }
        }
    });
    return isfind;
}

common.setRowIndex = (treedata, rowindex = 0) => {
    treedata.forEach(item => {
        item.x_rowindex = rowindex++;
        item.x_css = ms.getRowStat(item, item.x_rowindex);
        if (basic.isArrayExist(item.x_children)) {
            if (item.x_isopen) {
                rowindex = common.setRowIndex(item.x_children, rowindex);
            }
            else {
                common.setRowIndex(item.x_children, -9999999);
            }
        }
    });
    return rowindex;
}

common.getRowByIndex = (treedata, rowindex = -1) => {

    let itemfind = {};
    if (rowindex >= 0) {
        treedata.forEach(item => {
            if (!common.basic.isObjExist(itemfind)) {
                if (item.x_rowindex == rowindex) {
                    itemfind = item;
                }
                else {
                    if (basic.isArrayExist(item.x_children)) {
                        itemfind = common.getRowByIndex(item.x_children, rowindex);
                    }
                }
            }

        });
    }

    return itemfind
}

common.getRowById = (treedata, id) => {
    return common.getRowByFn(treedata, "id", id);
}

common.getRowByFn = (treedata, fn, v) => {
    let itemfind = {};
    treedata.forEach(item => {
        if (!common.basic.isObjExist(itemfind)) {
            if (item[fn] == v) {
                itemfind = item;
            }
            else {
                if (basic.isArrayExist(item.x_children)) {
                    itemfind = common.getRowByFn(item.x_children, fn, v);
                }
            }
        }

    });
    return itemfind
}


////////////////////Ajax
common.fetch = (jsondata, callback, endback) => {
    var defaultdata = {
        'msg': 'Loading', 'isautoback': true, 'exts': '', 'api': FFAPI, 'act': '', 'needid': false
    };
    jsondata = Object.assign(defaultdata, jsondata);
    let randlink = 'dt=' + (new Date()).getTime();
    jsondata.exts = jsondata.exts == '' ? randlink : randlink + "&" + jsondata.exts;

    if (jsondata.api != '') { jsondata.api += '/'; }
    let myloading = common.loading(jsondata.msg);
    ajax({
        url: '/api.ashx',
        method: 'get',
        params: {
            'url': jsondata.api + jsondata.act,
            'exts': jsondata.exts
        }
    }).then(res => {
        common.loadingClose(myloading);
        if ((process.env.NODE_ENV == 'debug') || (process.env.NODE_ENV == 'development')) {
            console.log(jsondata, res);
        }
        if (jsondata.isautoback) {
            if (endback != undefined) {
                endback();
            }
            if (res.bv == 1) {
                if (callback != undefined) {
                    res.data = basic.chEmpty(res.data) ? {} : JSON.parse(res.data);
                    if (jsondata.needid) {
                        if (res.data.infodata.id) {
                            callback(res.data);
                        }
                        else {
                            window.location = '/';
                        }
                    }
                    else {
                        callback(res.data);
                    }

                }
            }
            else {
                common.alert(res.msg);
            }
        }
        else {
            callback(res)
        }
    })
        .catch((err) => {
            common.loadingClose(myloading);
            common.alert('errinternal');
            if ((process.env.NODE_ENV == 'debug') || (process.env.NODE_ENV == 'development')) {
                console.log(err);
            }
        });
};

common.post = (jsondata, callback, endback) => {
    var defaultdata = { 'msg': 'Loading', 'isautoback': true, 'needid': false };
    jsondata = Object.assign(defaultdata, jsondata);
    if (basic.isObjExist(jsondata.postdata.data)) {
        jsondata.postdata.data = basic.replacePlus(JSON.stringify(jsondata.postdata.data));
    }
    if (basic.isObjExist(jsondata.postdata.exts)) {
        jsondata.postdata.exts = basic.replacePlus(JSON.stringify(jsondata.postdata.exts));
    }
    if (basic.chEmpty(jsondata.postdata.api)) { jsondata.postdata.api = FFAPI; }
    if (basic.chEmpty(jsondata.postdata.act)) { jsondata.postdata.act = FFACT; }
    let myloading = common.loading(jsondata.msg);
    ajax({
        url: '/api.ashx',
        method: 'post',
        data: jsondata.postdata
    }).then(res => {
        common.loadingClose(myloading);
        if ((process.env.NODE_ENV == 'debug') || (process.env.NODE_ENV == 'development')) {
            console.log(jsondata, res);
        }
        if (jsondata.isautoback) {
            if (endback != undefined) {
                endback();
            }
            if (res.bv == 1) {
                if (callback != undefined) {
                    res.data = basic.chEmpty(res.data) ? {} : JSON.parse(res.data);
                    let datadefault = { "cpage": 1, "ps": 15, "pagemax": 1, "recordcount": 1 }
                    res.data = Object.assign({}, datadefault, res.data);

                    if (jsondata.needid) {
                        if (res.data.infodata.id) {
                            callback(res.data);
                        }
                        else {
                            window.location = '/';
                        }
                    }
                    else {
                        callback(res.data);
                    }
                }
            }
            else {
                if (res.bv <= 0) {
                    switch (res.bv) {
                        case -1000:
                            res.msg = 'needlogin';
                            store.setIsLogin(0);
                            window.location = '/';
                            break;
                        case -1001:
                            res.msg = 'Illegaloperation';
                            window.location = '/';
                            break;
                        default:
                            common.alert(res.msg);
                            break;
                    }
                }


            }
        }
        else {
            callback(res)
        }
    })
        /*.catch(() => {
            common.loadingClose(myloading);
            common.alert('errinternal');
            //console.log(err);
        });*/
        .catch((err) => {
            common.loadingClose(myloading);
            common.alert('errinternal');
            if ((process.env.NODE_ENV == 'debug') || (process.env.NODE_ENV == 'development')) {
                console.log(err);
            }
        });
};

common.logOut = () => {
    common.fetch({ 'act': 'logout', 'msg': '' }, () => {
        store.setIsLogin(0);
        window.location = '/login';
    });
};


///////////////////前台

///////////

export default common
